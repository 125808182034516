.faq-accordion{
    .accordion-item{
        border: 0 !important;
    }
    .accordion-button{
        border-bottom: 1px solid $black ;
    }
    .accordion-button:not(.collapsed){
        background-color: transparent !important;
        border: none !important;
    }
    .accordion-item:last-of-type .accordion-button.collapsed{
        border-radius:  0 !important;
    }
    .accordion-button:focus{
        box-shadow: none !important;
        border-color: $black;
    }
    .accordion-button:not(.collapsed)::after{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28' fill='none'%3E%3Cpath d='M25.7383 14.2042L14.2383 14.2042M14.2383 14.2042L2.73828 14.2042M14.2383 14.2042L14.2383 2.7041M14.2383 14.2042L14.2383 25.7041' stroke='%233C3744' strokeWidth='4' strokeLinecap='round'/%3E%3C/svg%3E") !important;
    }
    .accordion-button::after{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='21' viewBox='0 0 22 21' fill='none'%3E%3Cpath d='M19.1317 18.5994L10.9999 10.4676M10.9999 10.4676L2.8682 2.3359M10.9999 10.4676L19.1317 2.33583M10.9999 10.4676L2.86827 18.5993' stroke='%233C3744' strokeWidth='4' strokeLinecap='round'/%3E%3C/svg%3E");
    }
}