.btn {
    &.btn-lg {
        padding: 1rem 3rem;
        font-size: 1rem;
    }
    &.btn-outline-white {
        color: #fff;
        background-color: transparent;
        border-radius: 2.2rem;
        border: 1px solid #fff;
    }
    &.btn-outline-dark {
        padding: 0.4rem;
        border-radius: 0.4rem;
        border: 1px solid rgba(54, 49, 61, 0.5);
        background-color: transparent;
    }
    &.btn-white {
        color: $black;
        background-color: $white;
        border-radius: 2.2rem;
        border: 1px solid #fff;
        &.btn-sm{
            padding: 0.5rem 0.75rem;
        }
    }
    &.btn-dark {
        color: $white;
        background-color: $black;
        border-radius: 2.2rem;
        border: 1px solid $black;
    }
    &.btn-darkblue {
        font-family: "Catesque";
        font-weight: 600;
        color: $darkblue;
    }
}
.link-button {
    color: $link;
    font-weight: 600;
}
.button-shadow {
    appearance: button;
    border: solid transparent;
    border-radius: 16px;
    border-width: 0 0 4px;
    box-sizing: border-box;
    color: #ffffff;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2rem;
    font-family: "Gilroy", sans-serif;
    font-weight: 800;
    letter-spacing: 0.8px;
    line-height: 20px;
    margin: 0;
    outline: none;
    overflow: visible;
    padding: 13px 16px;
    text-align: center;
    text-transform: uppercase;
    touch-action: manipulation;
    transform: translateZ(0);
    transition: filter 0.2s;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: middle;
    white-space: nowrap;

    &::after {
        background-clip: padding-box;
        border: solid transparent;
        border-radius: 16px;
        border-width: 0 0 4px;
        bottom: -4px;
        content: "";
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;
    }
    &::hover:not(:disabled) {
        filter: brightness(1.1);
        -webkit-filter: brightness(1.1);
    }
    &:active {
        border-width: 4px 0 0;
        background: none;
    }
    &.shadow-blue {
        background-color: #3f22ec;
        padding: 1rem 4.3rem;
        &::after {
            background-color: $blue;
        }
    }
    &.shadow-gray {
        background-color: #cbcbcb;

        padding: 1rem 4.3rem;
        color: rgba(34, 34, 34, 0.5);

        &::after {
            background-color: #e5e5e5;
        }
    }
    &.shadow-dark{
        background-color: #3C3744;
        border-color: #242227;
    }
}
