@import "./assets/scss/style.scss";
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

body {
  font-family: "Gopher Text", sans-serif;
  color: $white;
  background-color: $darkblue;
}
.page-wrapper {
  width: 100%;
  background-color: $darkblue;
}

a {
  text-decoration: none;
  color: inherit;
}
.fixed-page-wrapper {
  margin-top: 110px;
  min-height: 100%;
}
@media (max-width: 1024px) {
  .fixed-page-wrapper {
    margin-top: 75px !important;
  }
}
.about-text-title {
  font-size: 2.3rem;
  font-weight: 400;
  line-height: 3.6rem; /* 156.522% */
}
.about-paragraph {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.3rem; /* 162.5% */
}
.password-visible {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
.sozlesme {
  margin-top: 6.5rem;
  .sozlesme-title {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3.6rem; /* 144% */
  }
  .sozlesme-main-text {
    color: rgba(255, 255, 255, 0.5);

    font-size: 1rem;
    font-weight: 500;
    line-height: 2.15rem; /* 215% */
  }
  .sozlesme-full {
    margin-top: 5.5rem;
    color: rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    font-weight: 400;
    line-height: 2.15rem; /* 215% */

    .madde-baslik {
      font-size: 1.1rem;
      font-weight: 600;
      color: $white;
      line-height: 2.15rem; /* 195.455% */
    }
  }
}
.Toastify__toast-container {
  z-index: 9999999;
}

.loader {
  position: fixed;
  z-index: 9999999999;
  width: 100%;
  height: 100%;
  background-color: rgba($color: #1111, $alpha: 0.1);
  display: grid;
  place-items: center;
}
.small-popup {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: $black, $alpha: 0.2);
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  display: none;
  &.active {
    display: flex;
  }
  .popup-body {
    background-color: $white;
    width: 50%;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    padding: 1rem;
  }
}
.mt10 {
  margin-top: 10rem;
}

@media (max-width: 990px) {
  .small-popup {
    padding: 1rem;
    .popup-body {
      width: 100% !important;
    }
  }
}
